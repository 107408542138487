<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
.btns {
	margin-top: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.btn {
		width: 3rem;
		height: 0.9rem;
		font-size: 0.3rem;
		line-height: 0.9rem;
		text-align: center;
		border-radius: 0.08rem;
	}
	.btn_cancel {
		border: 1px solid $color_main;
		color: $color_main;
		background-color: #fff;
		margin-right: 0.4rem;
	}
	.btn_sure {
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
}
</style>

<template>
	<div class="add_user_2">
		<top-nav @back="back">被保人信息</top-nav>

		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-cell-group :border="false">
				<van-field label="名称" v-model="userInfo.name" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="被保人或被保公司名称" :rules="rules.name" />
				<van-field label="证件号码" v-model="userInfo.cardNo" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="统一社会信用代码(或身份证号码)" :rules="rules.cardNum" />
				<van-field label="联系地址" v-model="userInfo.address" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="联系地址" :rules="rules.address" />
			</van-cell-group>
		</van-form>

		<div class="btns">
			<div class="btn btn_cancel" @click="cancel">取消</div>
			<div class="btn btn_sure" @click="sure">确定</div>
		</div>
		<page-footer p></page-footer>
	</div>
</template>

<script>
import { CellGroup, Field, Form, Toast } from 'vant';
import Mtils from 'mtils';
import bus from '@/components/bus';

export default {
	name: 'addUser2', // 添加被保人，建工一切险使用
	components: {
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Form.name]: Form,
		[Toast.name]: Toast,
	},
	data() {
		return {
			// 被保险人
			userInfo: {
				id: '',
				name: '',
				cardNo: '',
				address: '',
			},

			rules: {
				name: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				cardNum: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				address: [{ required: true }],
				creditCode: [{ required: true }, { validator: this.creditCode, message: '证件号码有误' }],
			},
		};
	},
	created() {
		this.editInit();
	},
	methods: {
		back() {
			this.$router.go(-1);
		},

		// 编辑信息初始化
		editInit() {
			let query = this.$route.query;
			console.log(query);
			if (query.type === 'edit' && query.hasOwnProperty('userInfo')) {
				Object.assign(this.userInfo, JSON.parse(query.userInfo));
				console.log(this.userInfo);
			}
		},

		cancel() {
			this.back();
		},

		sure() {
			this.$refs.form.validate().then(() => {
				bus.$emit('receiveInsureDataAdd', this.userInfo);
				this.back();
			});
		},

		// 被保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 证件号码校验方法
		cardNumCheck(val) {
			return Mtils.validation.isIdCard(val) || Mtils.validation.isCreditCode(val);
		},

		// 社会统一信用代码校验
		creditCode(val) {
			return Mtils.validation.isCreditCode(val);
		},
	},
};
</script>
